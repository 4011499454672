.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.darkModeDatePicker .rdrStaticRange {
  border-bottom: 1px solid rgb(41, 41, 41);
}

.darkModeDatePicker .rdrStaticRangeLabel {
  background-color: #171717;
  color: white;
}

.darkModeDatePicker .rdrDefinedRangesWrapper {
  background-color: #171717;
  color: white;
  border-right: 1px solid rgb(41, 41, 41);
}

.darkModeDatePicker .rdrInputRangeInput {
  background-color: #171717;
  color: white;
  border: 1px solid rgb(41, 41, 41);
}

.darkModeDatePicker .rdrCalendarWrapper {
  background-color: #171717;
  color: white;
}

.darkModeDatePicker .rdrDateDisplayWrapper {
  background-color: rgb(41, 41, 41);
}

.darkModeDatePicker .rdrDateDisplayItem {
  background-color: #171717;
}

.darkModeDatePicker .rdrDateDisplayItemActive {
  border-color: #555555;
}

.darkModeDatePicker .rdrNextPrevButton {
  background-color: rgb(41, 41, 41);
  color: white;
}

.darkModeDatePicker .rdrDayDisabled {
  background-color: rgb(41, 41, 41);
}

.darkModeDatePicker .rdrStartEdge {
  background-color: #555555;
}

.darkModeDatePicker .rdrEndEdge {
  background-color: #555555;
}

.darkModeDatePicker .rdrInRange {
  background-color: #555555;
}

.darkModeDatePicker .rdrDayNumber span {
  color: white;
}

.darkModeDatePicker .rdrDayDisabled .rdrDayNumber span {
  color: #555555;
}

.darkModeDatePicker .rdrDayEndPreview {
  border-color: #555555;
}

.darkModeDatePicker .rdrDayStartPreview {
  border-color: #555555;
}

.darkModeDatePicker .rdrDayInPreview {
  border-color: #555555;
}